<template>
    <div class="box">
        <div class="null-page" v-show="yes"></div>

        <div class="my-account">
            <div class="account-wrap">
                <div class="account-left">
                    <div class="title">我的资产(积分)</div>
                    <!-- <div class="money">
						<div class="balance-money">
							<b>{{ integer }}</b>
							.
							<span>{{ decimal }}</span>
						</div>
						<div class="cz" @click="rechargeList">充值</div>
					</div> -->

                </div>


            </div>
            <div class="money" style="position: relative;">
                <div class="txt">账户余额：</div>
                <div class="price">￥{{ integer }}.{{ decimal }}</div>
                <div class="item-wrap" style="position: absolute;left: 0;top: 0;">
                    <div class="item">
                        <div class="iconfont iconziyuan"></div>
                        <div class="title">可提现余额:</div>
                        <b class="num">{{ balanceInfo.balance_money }}</b>
                    </div>
                    <div class="item">
                        <div class="iconfont iconziyuan"></div>
                        <div class="title">不可提现余额:</div>
                        <b class="num">{{ balanceInfo.balance }}</b>
                    </div>
                </div>
            </div>
            <div class="tab-warp">
                <el-tabs class="tab" v-model="tabValue" @tab-click="handleClick">
                    <el-tab-pane label="余额明细" name="balance"></el-tab-pane>
                    <el-tab-pane label="充值记录" name="record"></el-tab-pane>
                </el-tabs>
                <div class="btn recharge" @click="applyWithdrawal" style="margin-right: 10px;">余额提现</div>
                <div class="btn recharge" @click="rechargeList">在线充值</div>
            </div>
            <block v-if="tabValue == 'balance'">
                <div class="detail" v-loading="loading">
                    <el-table :data="accountList" border>
                        <el-table-column prop="type_name" label="来源" width="150"></el-table-column>
                        <el-table-column prop="account_data" label="金额" width="150"></el-table-column>
                        <el-table-column prop="remark" class="detail-name" label="详细说明"></el-table-column>
                        <el-table-column prop="time" label="时间" width="180"></el-table-column>
                    </el-table>
                </div>
                <div class="pager">
                    <el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
                        :current-page.sync="account.page" :page-size.sync="account.page_size"
                        @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
                        hide-on-single-page></el-pagination>
                </div>
            </block>
            <block v-if="tabValue == 'record'">
                <div class="order-list">
                    <nav>
                        <li>套餐名称</li>
                        <li>面值（积分）</li>
                        <li>购买价格</li>
                        <li>赠送福豆</li>
                        <li>赠送成长值</li>
                    </nav>

                    <div class="list" v-if="orderList.length > 0">
                        <div class="item" v-for="(orderItem, orderIndex) in orderList" :key="orderIndex">
                            <div class="head">
                                <span class="create-time">{{ $util.timeStampTurnTime(orderItem.create_time) }}</span>
                                <span class="order-no">订单号：{{ orderItem.order_no }}</span>
                            </div>
                            <ul>
                                <li>
                                    <div class="img-wrap"><el-image :src="$img(orderItem.cover_img)" fit="cover"
                                            @error="imageError(orderIndex)"></el-image></div>
                                    <div class="info-wrap">
                                        <h5 :title="orderItem.recharge_name">{{ orderItem.recharge_name }}</h5>
                                        <!-- <span>规格：规格值</span> -->
                                    </div>
                                </li>
                                <li>
                                    <span>￥{{ orderItem.face_value }}</span>
                                </li>
                                <li>
                                    <span>￥{{ orderItem.buy_price }}</span>
                                </li>
                                <li>
                                    <span>{{ orderItem.point }}</span>
                                </li>
                                <li>
                                    <span>{{ orderItem.growth }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-else-if="!loading && orderList.length == 0" class="empty-wrap">暂无相关订单</div>
                </div>

                <div class="pager">
                    <el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
                        :current-page.sync="currentPage" :page-size.sync="pageSize"
                        @size-change="recordHandlePageSizeChange" @current-change="recordHandleCurrentPageChange"
                        hide-on-single-page></el-pagination>
                </div>
            </block>
        </div>
    </div>
</template>

<script>
import { balance, withdrawConfig, balanceDetail, rechargeOrder } from "@/api/member/account"

import { mapGetters } from 'vuex';
export default {
    name: "account",
    components: {},
    data: () => {
        return {
            account: {
                page: 1,
                page_size: 10
            },
            balanceInfo: {
                balance: 0,
                balance_money: 0
            },
            accountList: [],
            total: 0,
            integer: 0,
            decimal: 0,
            loading: true,
            yes: true,
            tabValue: "balance",
            orderList: [],
            currentPage: 1,
            pageSize: 10,
        }
    },
    computed: {
        ...mapGetters(['defaultGoodsImage'])
    },
    created() {

        if (this.$route.query.tab) {
            this.tabValue = this.$route.query.tab
        }
        this.getList()

    },
    mounted() {
        let self = this;
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    methods: {
        //获取列表
        getList() {
            if (this.tabValue == 'balance') {
                this.getAccount(), this.getAccountList()
            } else if (this.tabValue == 'record') {
                this.getListData()
            }
        },
        //获取余额信息
        getAccount() {
            balance({ account_type: "balance,balance_money" })
                .then(res => {
                    if (res.code == 0 && res.data) {
                        this.balanceInfo = res.data
                        const price = (parseFloat(this.balanceInfo.balance) + parseFloat(this.balanceInfo.balance_money)).toFixed(2)
                        let priceSplit = price.split(".")
                        this.integer = priceSplit[0]
                        this.decimal = priceSplit[1]
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$message.error(err.message)
                })
        },
        //获取余额明细
        getAccountList() {
            balanceDetail({
                page_size: this.account.page_size,
                page: this.account.page,
                account_type: "balance"
            })
                .then(res => {
                    if (res.code == 0 && res.data) {
                        this.accountList = res.data.list
                        this.total = res.data.count
                        this.accountList.forEach(item => {
                            item.time = this.$util.timeStampTurnTime(item.create_time)
                        })
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
        },
        handlePageSizeChange(num) {
            this.account.page_size = num
            this.getAccountList()
        },
        recordHandlePageSizeChange(size) {
            this.pageSize = size
            this.refresh()
        },
        handleCurrentPageChange(page) {
            this.account.page = page
            this.getAccountList()
        },
        recordHandleCurrentPageChange(page) {
            this.currentPage = page
            this.refresh()
        },
        applyWithdrawal() {
            this.$router.push("/member/apply_withdrawal")
        },
        rechargeList() {
            this.$router.push("/member/recharge_list")
        },
        handleClick(tab, event) {
            this.account.page = 1
            this.tabValue = tab.name
            this.getList()
        },
        refresh() {
            this.loading = true
            this.getListData()
        },
        getListData() {
            rechargeOrder({
                page: this.currentPage,
                page_size: this.pageSize
            }).then(res => {
                if (res.code == 0 && res.data) {
                    this.orderList = res.data.list;
                } else {
                    this.$message.warning(res.message)
                }
                this.loading = false
            }).catch(err => {
                this.loading = false
            })
        },
        imageError(index) {
            this.orderList[index].cover_img = this.defaultGoodsImage;
        },
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;
}

.null-page {
    width: 100%;
    height: 730px;
    background-color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}

.my-account {
    background: #ffffff;
    padding: 20px;

    .account-wrap {
        display: flex;
        margin-bottom: 10px;

        .account-left {
            flex: 1;

            .title {
                font-size: $ns-font-size-base;
                font-weight: 600;
                border-bottom: 1px solid #E4E7ED;
                padding-bottom: 3px;
            }
        }



    }

    .money {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #E4E7ED;

        .price {
            font-size: 40px;
            color: red;
            padding: 0 2px;
            font-weight: 700;
        }

        .item-wrap {

            .item {
                display: flex;
                align-items: center;

                .title {
                    margin-left: 3px;
                }

                .num {
                    margin-left: 3px;
                }
            }
        }

    }

    .page {
        display: flex;
        justify-content: center;
        align-content: center;
        padding-top: 20px;
    }
}

.tab-warp {
    display: flex;
    justify-content: space-between;

    .tab {
        flex: 1;
    }

    .recharge {
        background: red;
        color: #fff;
        font-size: 13px;
        // padding: 2px 10px;
        border-radius: 3px;
        height: 25px;
        line-height: 25px;
        width: 60px;
        text-align: center;
        margin-top: 6px;

    }
}
</style>
<style>
.el-tabs__nav-wrap {
    overflow: visible;
}

.el-tabs__nav-wrap::after {
    width: calc(100% + 130px);
}
</style>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;
}

.null-page {
    width: 100%;
    height: 730px;
    background-color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}

.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
    box-shadow: unset;
}

.el-card {
    border: 0;
}

.order-list {
    nav {
        overflow: hidden;
        padding: 10px;
        background: #fff;
        margin-bottom: 10px;
        border-bottom: 1px solid #eeeeee;

        li {
            float: left;

            &:nth-child(1) {
                width: 40%;
            }

            &:nth-child(2) {
                width: 15%;
            }

            &:nth-child(3) {
                width: 15%;
            }

            &:nth-child(4) {
                width: 15%;
            }

            &:nth-child(5) {
                width: 15%;
            }
        }
    }

    .list {
        .item {
            margin-bottom: 20px;
            border: 1px solid #eeeeee;
            border-top: 0;

            .head {
                padding: 8px 10px;
                background: #f7f7f7;
                font-size: 12px;

                .create-time {
                    margin-right: 10px;
                }

                border-bottom: 1px solid #eeeeee;

                a {
                    margin: 0 10px 0 20px;
                }

                .order-type {
                    margin-left: 30px;
                }
            }
        }

        ul {
            background-color: #fff;
            padding: 10px;
            overflow: hidden;

            li {
                float: left;
                line-height: 60px;

                &:nth-child(1) {
                    width: 40%;
                    line-height: inherit;

                    .img-wrap {
                        width: 60px;
                        height: 60px;
                        float: left;
                        margin-right: 10px;
                        border-radius: 5px;
                        overflow: hidden;

                        .el-image {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .info-wrap {
                        margin-left: 70px;

                        h5 {
                            font-weight: normal;
                            font-size: $ns-font-size-base;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            margin-right: 10px;
                        }
                    }
                }

                &:nth-child(2) {
                    width: 15%;
                }

                &:nth-child(3) {
                    width: 15%;
                }

                &:nth-child(4) {
                    width: 15%;
                }

                &:nth-child(5) {
                    width: 15%;
                }
            }
        }
    }

    .empty-wrap {
        text-align: center;
        padding: 10px;
    }
}
</style>